import { v4 as uuidv4 } from "uuid";

const songData = [
    {
        title: "Disco Ears",
        artist: "Brian Blade",
        coverUrl:
            "./1.jpeg",
        thumbUrl:
            "./1.jpeg",
        audio: "./1.mp3",
        palette: "coral",
        id: uuidv4(),
    },
    {
        title: "Fly Me to the Moon",
        artist: "Frank Sinatra",
        coverUrl:
            "./2.jpeg",
        thumbUrl:
            "./2.jpeg",
        audio: "./2.mp3",
        palette: "yellow",
        id: uuidv4(),
    },
    {
        title: "Gift of the Moon",
        artist: "Juilius Rodriguez",
        coverUrl:
            "https://api.audiohut.ackr8.com/Jazz/3.jpg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Jazz/3.jpg",
        audio: "https://api.audiohut.ackr8.com/Jazz/3.mp3",
        palette: "purple",
        id: uuidv4(),
    },
    {
        title: "Summertime",
        artist: "Louis Armstrong Ella Fitzgerald",
        coverUrl:
            "https://api.audiohut.ackr8.com/Jazz/4.jpg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Jazz/4.jpg",
        audio: "https://api.audiohut.ackr8.com/Jazz/4.mp3",
        palette: "green",
        id: uuidv4(),
    },

    {
        title: "Trinity",
        artist: "Snarky Puppy",
        coverUrl:
            "https://api.audiohut.ackr8.com/Jazz/5.jpg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Jazz/5.jpg",
        audio: "https://api.audiohut.ackr8.com/Jazz/5.mp3",
        palette: "purple",
        id: uuidv4(),
    },
];

export default songData;